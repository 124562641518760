var elem = "";
var jsWidgetlogoutElement;
var rb_user_fname;
var rb_user_Mname = '';
var rb_user_lname = '';
var rb_user;
var rb_user_email;
var usertoken = {};
var userInfo;
var islogIn;
var UserlogOut;
var boxProfile;
var isLoggedIN = ''
var amsurl;
var XAuth;
function RB_AMS_SDK(options){
	this.options = {
		'userInfo' : null,
		'islogIn' : null,
		'UserlogOut' : null,
		'amsurl' : null,
		'loginBoxStyle1' : true,
		'loginBoxStyle2' : false,
		'widgetColor' : '#ef663c',
		'widgetFont' : '12px'
	}
	this.options = extend(this.options , options)
	userInfo = options.userInfo;
	islogIn = options.islogIn;
	UserlogOut = options.UserlogOut;
	amsurl = options.amsurl;
	circleProfile = this.options.circleProfile;
	boxProfile = this.options.boxProfile;
	loginBoxStyle1 = this.options.loginBoxStyle1;
	loginBoxStyle2 = this.options.loginBoxStyle2;
	widgetColor = this.options.widgetColor;
	widgetFont = this.options.widgetFont;
	var cssId = 'myCss';  // you could encode the css path itself to generate id..
	if (!document.getElementById(cssId))
	{
		var head  = document.getElementsByTagName('head')[0];
		var link  = document.createElement('link');
		link.id   = cssId;
		link.rel  = 'stylesheet';
		link.type = 'text/css';
		link.href = amsurl + 'static/css/loginSdkStyling.css';
		link.media = 'all';
		head.appendChild(link);
	}
	if(userInfo){
		userInfo.setAttribute("style", "color:" + widgetColor + ";font-size:" + widgetFont);
	}

	XAuth = (function() {
		// Reference shortcut so minifier can save on characters
		var win = window;

		// Check for browser capabilities
		var unsupported = !(win.postMessage && win.localStorage && win.JSON);
		if(!amsurl){
			console.log("Requested domain is undefined.");
			return false
		}
		var XAuthHostname = amsurl;
		//var XAuthHostname = "accounts.rbstaging.in";
		// TODO: https support. Needs CDN to have a proper cert
		var XAuthServerUrl = XAuthHostname;

		// Cached references
		var iframe = null;
		var postWindow = null;

		// Requests are done asynchronously so we add numeric ids to each
		// postMessage request object. References to the request objects
		// are stored in the openRequests object keyed by the request id.
		var openRequests = {};
		var requestId = 0;

		// All requests made before the iframe is ready are queued (referenced by
		// request id) in the requestQueue array and then called in order after
		// the iframe has messaged to us that it's ready for communication
		var requestQueue = [];

		// Listener for window message events, receives messages from only
		// the xauth domain that we set up in the iframe
		function onMessage(event) {
			// event.origin will always be of the format scheme://hostname:port
			// http://www.whatwg.org/specs/web-apps/current-work/multipage/comms.html#dom-messageevent-origin
			// var originHostname = event.origin.split('://')[1].split(':')[0];
			// if(originHostname != XAuthHostname) {
			// 	debugger;
			// 	// Doesn't match xauth.org, reject
			// 	return;
			// }
			
			// unfreeze request message into object
			if(event.data){
				var msg = '';
				try {
			       msg = JSON.parse(event.data);
			    } catch (e) {
			    	msg = event.data;
			    }
				usertoken = msg.tokens		
			}


			if(!(usertoken == null && usertoken == undefined)){
				if(userInfo){
					RB_AMS_SDK.prototype.jsWidgetcreateDiv(userInfo);
				}
				if(usertoken.hasOwnProperty('items')){
					// localStorage.setItem(usertoken, msg.tokens);
					islogIn(usertoken.items);
				}
			};
			if(!msg) {
				return;
			}

			// Check for special i: ready message and call any pending
			// requests in our queue made before the iframe was created.
			if(msg.cmd == 'xauth::ready') {
				// Cache the reference to the iframe window object
				postWindow = iframe.contentWindow;
				setTimeout(makePendingRequests, 0);
				return;
			}

			// Look up saved request object and send response message to callback
			var request = openRequests[msg.id];
			if(request) {
				if(request.callback) {
					request.callback(msg);
				}
				delete openRequests[msg.id];
			}
		}

		// Called once on first command to create the iframe to xauth.org
		function setupWindow() {
			if(iframe || postWindow) { return; }
			
			// Create hidden iframe dom element
			var doc = win.document;
			iframe = doc.createElement('iframe');
			var iframeStyle = iframe.style;
			iframeStyle.position = 'absolute';
			iframeStyle.left = iframeStyle.top = '-999px';

			// Setup postMessage event listeners
			if (win.addEventListener) {
				win.addEventListener('message', onMessage, false);
			} else if(win.attachEvent) {
				win.attachEvent('onmessage', onMessage);
			}

			// Append iframe to the dom and load up xauth.org inside
			doc.body.appendChild(iframe);
			iframe.src = XAuthServerUrl + 'frame-page/';
		}
		
		// Called immediately after iframe has told us it's ready for communication
		function makePendingRequests() {
			for(var i=0; i<requestQueue.length; i++) {
				makeRequest(openRequests[requestQueue.shift()]);
			}
		}

		// Simple wrapper for the postMessage command that sends serialized requests
		// to the xauth.org iframe window
		function makeRequest(requestObj) {
			postWindow.postMessage(JSON.stringify(requestObj), XAuthServerUrl);
		}

		// All requests funnel thru queueRequest which assigns it a unique
		// request Id and either queues up the request before the iframe
		// is created or makes the actual request
		function queueRequest(requestObj) {
			if(unsupported) { 
				console.log("Something went wrong.")
				return false; 
			}
			requestObj.id = requestId;
			openRequests[requestId++] = requestObj;

			// If window isn't ready, add it to a queue
			if(!iframe || !postWindow) {
				requestQueue.push(requestObj.id);
				setupWindow(); // must happen after we've added to the queue
			} else {
				makeRequest(requestObj);
			}
		}
		
		// Following three functions are just API wrappers that clean up the
		// the arguments passed in before they're sent and attach the
		// appropriate command strings to the request objects

		function callRetrieve(args) {
			if(!args) { args = {}; }
			var requestObj = {
				cmd: 'xauth::retrieve',
				retrieve: args.retrieve || ['items'],
				callback: args.callback || null	
			}
			queueRequest(requestObj);
		}
		
		function callExtend(args) {
			var today = new Date();
			var dd = today.getDate() + 10;
			var mm = today.getMonth()+1; 
			var yyyy = today.getFullYear();
			if(dd<10){
				dd='0'+dd;
			} 

			if(mm<10) 
			{
				mm='0'+mm;
			} 
			exipreDate = dd+'/'+mm+'/'+yyyy;

			if(!args) { args = {}; }
			var requestObj = {
				cmd: 'xauth::extend',
				token: args.token || 'asmgdsjgfsdh',
				expire: args.expire || exipreDate,
				extend: args.extend || [true],
				session: args.session || false,
				callback: args.callback || null
			}
			queueRequest(requestObj);
		}
		
		function callExpire(args) {
			usertoken = {}
			window.localStorage.clear();
			if(userInfo){
				RB_AMS_SDK.prototype.jsWidgetcreateDiv(userInfo);
			}
			if(!args) { args = {}; }
			var requestObj = {
				cmd: 'xauth::expire',
				callback: args.callback || function() { return 'Token removed'}
			}
			queueRequest(requestObj);
		}

		// Return XAuth object with exposed API calls
		return {
			extend: callExtend,
			retrieve: callRetrieve,
			expire: callExpire,
			disabled: unsupported // boolean, NOT a function
		};

	})();

	XAuth.retrieve();

};

function extend(target , source) {
	if (source) {
		for(var prop in source) {
			if(source.hasOwnProperty(prop)) {
				target[prop] = source[prop];
			}
		}
	}
	return target;
};
RB_AMS_SDK.prototype.jsWidgetcreateDiv = function(options){
	var tokenAllow;
	var userFirstName;
	var userMiddleName;
	var userLastName;
	var userCode;
	if(Object.keys(usertoken).length > 0){
		tokenAllow = true;
		token = usertoken.items.token;
		rb_user_fname = usertoken.items.first_name;
		if(usertoken.items.middle_name != null){
			rb_user_Mname = usertoken.items.middle_name;
		} 
		if(usertoken.items.last_name != null){
			rb_user_lname = usertoken.items.last_name;
		}
		rb_user = usertoken.items.username;
		//rb_user_email = usertoken.items.email;
		userFirstName = rb_user_fname[0];	
	} else{
		tokenAllow = false;
	}
	if(userInfo){
		if (tokenAllow) {
			userInfo.innerHTML = "";
			userInfo.innerHTML = '<span id="userProfile" class="circleStyle">' +  userFirstName + ' </span></div> <div id="profileDetailBox" style="position: absolute;z-index: 9999;width: 200px;padding-top: 10px;right: 0;"></div>';
			this.jsWidgetshowProfile();
		} else {
			if(UserlogOut){
				UserlogOut();
			}
			userInfo.innerHTML = "";
			userInfo.innerHTML = '<button id="widgetJslogin" class="login" style="cursor:pointer;">LOGIN</button>';
			var loginId = document.getElementById('widgetJslogin');
			if(loginBoxStyle2){
				loginId.classList.add("loginStyle2");
			}else{
				loginId.classList.add("loginStyle1");
				var loginStyle1 =  document.getElementById('widgetJslogin');
				loginStyle1.style.background = widgetColor;	
			}
		}
	}
	var loginId = document.getElementById('widgetJslogin');
    if(!(loginId == null)){
		document.getElementById('widgetJslogin').addEventListener('click' , function(){
			var pageUrl = window.location.href;
			window.location.replace(amsurl+'?next='+pageUrl);
		})
    }

}

RB_AMS_SDK.prototype.jsWidgetlogout = function(){
	// var proDetail = document.getElementById("profileDetailBox");
	// proDetail.innerHTML = '';
	if(userInfo){
		userInfo.innerHTML = '';
	}
	XAuth.expire();
	if(UserlogOut){
		UserlogOut();
	} 
};



RB_AMS_SDK.prototype.jsWidgetshowProfile = function() {
	var profile = document.getElementById("userProfile");
	if(profile != null) {
		this.jsWidgetDynamicMenu();
		profile.addEventListener('click', this.jsWidgetopenMenuOnClk, true);		
	}
}

RB_AMS_SDK.prototype.jsWidgetopenMenuOnClk = function() {
    var x =  document.getElementById('jav_widgetxtR');
    if (x.style.display === "none") {
        x.style.display = "block";
    } else {
        x.style.display = "none";
    }
}

//close menu
RB_AMS_SDK.prototype.jsWidgetcloseMenu = function() {
	while(sibling = options.nextElementSibling) {  
        sibling.parentNode.removeChild(sibling);     
	}
}	

RB_AMS_SDK.prototype.jsWidgetDynamicMenu = function() {
	var profileBox = '';
	var abc = 123 ;
	var element =  document.getElementById('jav_widgetxtR');
	var pageUrl = window.location.href;
	if (typeof(element) == 'undefined' || element == null)
	{
		profileBox += '<div class="jav_widgetxtR" id="jav_widgetxtR" style="padding: 5px; color:#444; display:none; width:100%;box-shadow: 0 0 10px rgba(0,0,0,0.6);-moz-box-shadow: 0 0 10px rgba(0,0,0,0.6);-webkit-box-shadow: 0 0 10px rgba(0,0,0,0.6);-o-box-shadow: 0 0 10px rgba(0,0,0,0.6);background: #fff;">' ;
		profileBox += '<div class="userFirstName">' + rb_user_fname + ' ' + rb_user_Mname + ' ' + rb_user_lname + '</div>';
		//profileBox += '<div class="userEmail">' + rb_user_email + '</div>';
		profileBox += '<div class="userCode">' + '(' + rb_user + ')' + '</div>';
		profileBox += '<div id="profilelink" onclick="RB_AMS_SDK.prototype.jsWidgetuserprofile()" style="text-transform: uppercase;font-size: 11px;font-weight: 600;color: #f1592a;">Profile</div>';
		profileBox += '<div><button id="jsWidgetlogout" onclick="RB_AMS_SDK.prototype.jsWidgetlogout()" style="border: 1px solid #ef663c;color: #fff;cursor: pointer;text-transform: uppercase;padding: 2px 9px;margin-top: 12px;">logout</button></div>'
		profileBox += '</div>'
		document.getElementById("profileDetailBox").innerHTML = profileBox
	}
	var jsWidgetlogout =  document.getElementById('jsWidgetlogout');
	jsWidgetlogout.style.background = widgetColor;
	var hostUrl = window.location.href;
	profileTextlink = document.getElementById('profilelink').innerHTML;

	//if(hostUrl.includes(amsurl) && profileTextlink == "Profile" && !(hostUrl.includes('?next')) ){
	if(hostUrl.indexOf(amsurl) > -1 && profileTextlink == "Profile"){
		document.getElementById("profilelink").innerHTML = '';
	}
	// else{
	// // 	if (pageUrl.includes('next') && hostUrl.includes(amsurl) ){
	// // 		document.getElementById("profilelink").innerHTML = "Home";
	// // 	} else{
	// 		document.getElementById("profilelink").innerHTML = "Profile";
	// // 	}
	// }
}

RB_AMS_SDK.prototype.jsWidgetuserprofile = function() {
	var pageUrl = window.location.href;
	//var profilelink =  document.getElementById('profilelink');
	var profileLinksPage;
	if (pageUrl.indexOf('next') > -1){
		profileLinksPage = pageUrl.split('next=')[1]
		window.open(profileLinksPage,'_blank')
		//window.location.replace(profileLinksPage);
		//document.getElementById("profilelink").innerHTML = "home";
	} else{
		//profileLinksPage = amsurl+'profile' +'?next='+pageUrl;
		profileLinksPage = amsurl+'profile';
		window.open(profileLinksPage,'_blank')
		//window.location.replace(profileLinksPage);
		// profileText = 'User Profile'
		// document.getElementById("profilelink").innerHTML = "profile";
	}
}